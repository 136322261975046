import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const MyImageGallery1 = ({ allproductosJson }) => {
  let recorreImages = [];

  allproductosJson.map((item) => {
    const images = {
      original: `https://disdelsa.com/imagenes/productos/${item.Imagen}`,
      thumbnail: `https://disdelsa.com/filemanager/file1007/imagenes/productos/small/${item.Imagen}`
    }
    recorreImages.push(images)
  })

  const propertie2 = {
    thumbnailPosition: "left",
    useBrowserFullscreen: true,
    showPlayButton: false,
    items: recorreImages
  };

  return <ImageGallery {...propertie2} />;
};

export default MyImageGallery1;
